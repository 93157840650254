function FeatureSection() {
  return (
    <section className="themart-featured-section">
      <div className="container mb-5">
        <div className="row">
          <div className="col-lg-6">
            <div className="wpo-section-title">
              <h2>We Specialize In</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card" style={{ maxWidth: '18rem' }}>
              <img
                src="/assets/images/children.webp"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body d-flex justify-content-center">
                <p className="card-text">Children's Books</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card" style={{ maxWidth: '18rem' }}>
              <img
                src="/assets/images/horror.webp"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body d-flex justify-content-center">
                <p className="card-text">Horror Books</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card" style={{ maxWidth: '18rem' }}>
              <img
                src="/assets/images/literary.jpeg"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body d-flex justify-content-center">
                <p className="card-text">Literary Fiction Books</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card" style={{ maxWidth: '18rem' }}>
              <img
                src="/assets/images/thriller.jpeg"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body d-flex justify-content-center">
                <p className="card-text">Thriller Books</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card" style={{ maxWidth: '18rem' }}>
              <img
                src="/assets/images/biography.jpeg"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body d-flex justify-content-center">
                <p className="card-text">Biography Books</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureSection;

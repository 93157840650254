function Footer() {
  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-lg-5 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <img src="/assets/images/logo-horizontal.png" alt="blog" />
                </div>
                <p>
                  We specialize in supporting authors by offering a range of
                  professional services tailored to their needs. We focus on
                  quality, creativity, and personalized assistance to ensure
                  every project stands out.
                </p>
                {/* <ul>
                  <li>
                    <span>
                      <i className="ti-facebook"></i>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="ti-twitter-alt"></i>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="ti-linkedin"></i>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="ti-instagram"></i>
                    </span>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>Contact Us</h3>
                </div>
                <div className="contact-ft">
                  <ul>
                    <li>
                      <i className="fi flaticon-mail"></i>
                      info@twentynine-services.com
                    </li>
                    <li>
                      <i className="fi flaticon-phone"></i>(+91) 9569256712
                    </li>
                    <li>
                      <i className="fi flaticon-pin"></i>
                      Flat no. LG - 01, P. no. 2 Shri Giriraj Vihar, Lalarpura,
                      Jaipur 302021
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>Policies</h3>
                </div>
                <ul>
                  <li>
                    <div
                      onClick={() =>
                        window.open(
                          '/privacy-policy',
                          '_blank',
                          'noopener,noreferrer'
                        )
                      }
                    >
                      Privacy
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() =>
                        window.open(
                          '/cancellation-and-refund',
                          '_blank',
                          'noopener,noreferrer'
                        )
                      }
                    >
                      Refunds & Cancellations
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() =>
                        window.open(
                          '/terms-and-conditions',
                          '_blank',
                          'noopener,noreferrer'
                        )
                      }
                    >
                      Terms & Conditions
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() =>
                        window.open(
                          '/shipping-and-delivery',
                          '_blank',
                          'noopener,noreferrer'
                        )
                      }
                    >
                      Shipping and Delivery
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <p className="copyright">
                {' '}
                Copyright &copy; 2024 Twenty Nine Services by{' '}
                <a href="index.html">wpOceans</a>. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import { Link } from 'react-router-dom';

function Contact() {
  return (
    <>
      <section className="wpo-page-title">
        <h2 className="d-none">Hide</h2>
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="wpo-breadcumb-wrap">
                <ol className="wpo-breadcumb-wrap">
                  <li>
                    <Link to={`/`}>Home</Link>
                  </li>
                  <li>Contact</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wpo-contact-pg-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-lg-10 offset-lg-1">
              <div className="office-info">
                <div className="row">
                  <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                    <div className="office-info-item">
                      <div className="office-info-icon">
                        <div className="icon">
                          <i className="fi flaticon-pin"></i>
                        </div>
                      </div>
                      <div className="office-info-text">
                        <h2>Address</h2>
                        <p>
                          Flat no. LG - 01, P. no. 2 Shri Giriraj Vihar,
                          Lalarpura, Jaipur 302021
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                    <div className="office-info-item office-info-item-padding-bottom">
                      <div className="office-info-icon">
                        <div className="icon">
                          <i className="fi flaticon-mail"></i>
                        </div>
                      </div>
                      <div className="office-info-text">
                        <h2>Email Us</h2>
                        <p>info@twentynine-services.com</p>
                      </div>
                    </div>
                  </div>
                  <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                    <div className="office-info-item office-info-item-padding-bottom">
                      <div className="office-info-icon">
                        <div className="icon">
                          <i className="fi flaticon-phone"></i>
                        </div>
                      </div>
                      <div className="office-info-text">
                        <h2>Call Now</h2>
                        <p>+91 9569256712</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpo-contact-title">
                <h2>Have Any Question?</h2>
                <p>
                  It is a long established fact that a reader will be distracted
                  content of a page when looking.
                </p>
              </div>
              <div className="wpo-contact-form-area">
                <form
                  method="post"
                  className="contact-validation-active"
                  id="contact-form-main"
                >
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      placeholder="Your Name*"
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email*"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      name="adress"
                      id="adress"
                      placeholder="Adress"
                    />
                  </div>
                  <div className="fullwidth">
                    <textarea
                      className="form-control"
                      name="note"
                      id="note"
                      placeholder="Message..."
                    ></textarea>
                  </div>
                  <div className="submit-area">
                    <button type="submit" className="theme-btn">
                      Get in Touch
                    </button>
                    <div id="loader">
                      <i className="ti-reload"></i>
                    </div>
                  </div>
                  <div className="clearfix error-handling-messages">
                    <div id="success">Thank you</div>
                    <div id="error">
                      {' '}
                      Error occurred while sending email. Please try again
                      later.{' '}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wpo-contact-map-section">
        <h2 className="hidden">Contact map</h2>
        <div className="wpo-contact-map">
          <iframe
            title="Interactive Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.06427758792!2d75.72354617522393!3d26.90145507665392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db4a55d11adf7%3A0x476e29b77af638f4!2s2%2C%20Giriraj%20Vihar%20Rd%2C%20Bhairav%20Nagar%2C%20Ayodhya%20Nagar%2C%20Jaipur%2C%20Rajasthan%20302034!5e0!3m2!1sen!2sin!4v1731840685510!5m2!1sen!2sin"
            allowfullscreen
          ></iframe>
        </div>
      </section>
    </>
  );
}

export default Contact;

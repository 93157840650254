import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrency,
  updatePrices,
  fetchConversionRate,
} from '../../store/slices/servicesSlice';
import { updateCartPrices } from '../../store/slices/cartSlice';

function Topbar() {
  const dispatch = useDispatch();
  const { currency, conversionRate } = useSelector((state) => state.services);

  const handleCurrencyChange = async (e) => {
    const newCurrency = e.target.value;
    dispatch(setCurrency(newCurrency));

    if (newCurrency === 'USD') {
      await dispatch(fetchConversionRate());
    }

    dispatch(updatePrices());
    dispatch(
      updateCartPrices({
        conversionRate,
        currency: newCurrency,
      })
    ); // Update cart prices
  };

  return (
    <div className="topbar">
      <div className="container">
        <div className="row">
          <div className="col col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="contact-intro">
              <span>
                Empowering Stories, One Page at a Time – Your Trusted Partner
                for All Things Books!
              </span>
            </div>
          </div>
          <div className="col col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="contact-info">
              <ul>
                <li>
                  <a href="tel:869968236">
                    <span>Need help? Call Us:</span> (+91) 9569256712
                  </a>
                </li>
                <li>
                  <div className="dropdown">
                    <select
                      className="dropdown-toggle"
                      value={currency}
                      onChange={handleCurrencyChange}
                    >
                      <option value="INR">INR</option>
                      <option value="USD">USD</option>
                    </select>
                    {/* <button
                      className="dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      USD
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton2"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          AUD
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          EUR
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          CNY
                        </a>
                      </li>
                    </ul> */}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topbar;

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

function Checkout() {
  const totalQuantity = useSelector((state) => state.cart?.totalQuantity);
  const cartData = useSelector((state) => state.cart?.items);
  const cartTotalPrice = useSelector((state) => state.cart?.totalPrice);
  const { currencySymbol } = useSelector((state) => state.services);

  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://restcountries.com/v3.1/all')
      .then((response) => response.json())
      .then((data) => {
        const countryNames = data.map((country) => country.name.common);
        setCountries(countryNames.sort()); // Sort alphabetically
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching countries:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <section className="wpo-page-title">
        <h2 className="d-none">Hide</h2>
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="wpo-breadcumb-wrap">
                <ol className="wpo-breadcumb-wrap">
                  <li>
                    <Link to={`/`}>Home</Link>
                  </li>
                  <li>
                    <Link to={`/cart`}>Cart</Link>
                  </li>
                  <li>Checkout</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="wpo-checkout-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="single-page-title">
                <h2>Your Checkout</h2>
                <p>There are {totalQuantity} products in this list</p>
              </div>
            </div>
          </div>
          <form>
            <div className="checkout-wrap">
              <div className="row">
                <div className="col-lg-8 col-12">
                  {/* <div className="row">
                    <div className="col-lg-6">
                      <div className="caupon-wrap s2">
                        <div className="coupon coupon-2">
                          <div id="toggle2">
                            <div className="text">
                              <i className="fi flaticon-user-profile"></i>{' '}
                              Returning customer?
                              <span>Click Here to Login</span>
                            </div>
                          </div>
                        </div>
                        <div className="create-account s2">
                          <span>
                            If you have shopped with us before, please enter
                            your details in the boxes below. If you are a new
                            customer, please proceed to the Billing & Shipping
                            section.
                          </span>
                          <div className="name-input">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Name"
                              required
                            />
                          </div>
                          <div className="name-email">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              required
                            />
                          </div>
                          <div className="input-wrap s1">
                            <button type="submit">Login</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="caupon-wrap s1">
                        <div className="coupon coupon-1">
                          <div id="toggle1">
                            <div className="text">
                              <i className="fi flaticon-coupon"></i>Have a
                              Coupon?
                              <span>Click Here to Enter</span>
                            </div>
                          </div>
                        </div>
                        <div className="create-account s1">
                          <span>If you have coupon code,please apply it</span>
                          <div className="input-wrap">
                            <input type="password" />
                            <button>Apply</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="caupon-wrap s3">
                    <div className="biling-item">
                      <div className="coupon coupon-3">
                        <h2>Billing Address</h2>
                      </div>
                      <div className="billing-adress">
                        <div className="contact-form form-style">
                          <div className="row">
                            <div className="col-lg-6 col-md-12 col-12">
                              <input
                                type="text"
                                placeholder="First Name*"
                                id="fname1"
                                name="fname"
                              />
                            </div>
                            <div className="col-lg-6 col-md-12 col-12">
                              <input
                                type="text"
                                placeholder="Last Name*"
                                id="fname2"
                                name="fname"
                              />
                            </div>
                            <div className="col-lg-6 col-md-12 col-12">
                              <select
                                name="address"
                                id="Country"
                                className="form-control"
                              >
                                <option disabled="" selected="">
                                  Country*
                                </option>
                                {countries.map((country, index) => (
                                  <option key={index}>{country}</option>
                                ))}
                              </select>
                            </div>
                            <div className="col-lg-6 col-md-12 col-12">
                              <input
                                type="text"
                                placeholder="City / Town*"
                                id="City"
                                name="City"
                              />
                            </div>
                            <div className="col-lg-6 col-md-12 col-12">
                              <input
                                type="text"
                                placeholder="Postcode / ZIP*"
                                id="Post2"
                                name="Post"
                              />
                            </div>
                            <div className="col-lg-6 col-md-12 col-12">
                              <input
                                type="text"
                                placeholder="Company Name*"
                                id="Company"
                                name="Company"
                              />
                            </div>
                            <div className="col-lg-6 col-md-12 col-12">
                              <input
                                type="text"
                                placeholder="Email Address*"
                                id="email4"
                                name="email"
                              />
                            </div>
                            <div className="col-lg-6 col-md-12 col-12">
                              <input
                                type="text"
                                placeholder="Phone*"
                                id="email2"
                                name="email"
                              />
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                              <input
                                type="text"
                                placeholder="Address*"
                                id="Adress"
                                name="Adress"
                              />
                            </div>
                            <div className="col-lg-12 col-md-12 col-12">
                              <div className="note-area">
                                <textarea
                                  name="massage"
                                  placeholder="Additional Information"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="cout-order-area">
                    <h3>Your Order</h3>
                    <div className="oreder-item">
                      <div className="title">
                        <h2>
                          Products <span>Subtotal</span>
                        </h2>
                      </div>
                      {cartData.map((item) => {
                        return (
                          <div className="oreder-product" key={item.id}>
                            <div className="product">
                              <ul>
                                <li className="first-cart">
                                  {item.name}(x{item.quantity})
                                </li>
                              </ul>
                            </div>
                            <span>
                              {currencySymbol} {item.totalPrice}
                            </span>
                          </div>
                        );
                      })}
                      <div className="title s2">
                        <h2>
                          Total
                          <span>
                            {currencySymbol} {cartTotalPrice}
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="caupon-wrap s5">
                    <div className="payment-area">
                      <div className="row">
                        <div className="col-12">
                          <div className="payment-option" id="open5">
                            <h3>Payment</h3>
                            {/* <div className="payment-select">
                              <ul>
                                <li className="addToggle">
                                  <input
                                    id="add"
                                    type="radio"
                                    name="payment"
                                    checked="checked"
                                    value="30"
                                  />
                                  <label for="add">Direct Bank Transfer</label>
                                </li>
                                <li className="removeToggle">
                                  <input
                                    id="remove"
                                    type="radio"
                                    name="payment"
                                    value="30"
                                  />
                                  <label for="remove">Cash on delivery</label>
                                </li>
                                <li className="getwayToggle">
                                  <input
                                    id="getway"
                                    type="radio"
                                    name="payment"
                                    value="30"
                                  />
                                  <label for="getway">Online Getway</label>
                                </li>
                              </ul>
                            </div> */}
                            <div id="open6" className="payment-name active">
                              {/* <ul>
                                <li className="visa">
                                  <input
                                    id="1"
                                    type="radio"
                                    name="size"
                                    value="30"
                                  />
                                  <label for="1">
                                    <img
                                      src="assets/images/checkout/img-1.png"
                                      alt=""
                                    />
                                  </label>
                                </li>
                                <li className="mas">
                                  <input
                                    id="2"
                                    type="radio"
                                    name="size"
                                    value="30"
                                  />
                                  <label for="2">
                                    <img
                                      src="assets/images/checkout/img-2.png"
                                      alt=""
                                    />
                                  </label>
                                </li>
                                <li className="ski">
                                  <input
                                    id="3"
                                    type="radio"
                                    name="size"
                                    value="30"
                                  />
                                  <label for="3">
                                    <img
                                      src="assets/images/checkout/img-3.png"
                                      alt=""
                                    />
                                  </label>
                                </li>
                                <li className="pay">
                                  <input
                                    id="4"
                                    type="radio"
                                    name="size"
                                    value="30"
                                  />
                                  <label for="4">
                                    <img
                                      src="assets/images/checkout/img-4.png"
                                      alt=""
                                    />
                                  </label>
                                </li>
                              </ul> */}
                              <div className="contact-form form-style">
                                <div className="row">
                                  {/* <div className="col-lg-6 col-md-12 col-12">
                                    <input
                                      type="text"
                                      placeholder="Card holder Name*"
                                      id="holder"
                                      name="name"
                                    />
                                  </div>
                                  <div className="col-lg-6 col-md-12 col-12">
                                    <input
                                      type="text"
                                      placeholder="Card Number*"
                                      id="card"
                                      name="card"
                                    />
                                  </div>
                                  <div className="col-lg-6 col-md-12 col-12">
                                    <input
                                      type="text"
                                      placeholder="CVV*"
                                      id="CVV"
                                      name="CVV"
                                    />
                                  </div>
                                  <div className="col-lg-6 col-md-12 col-12">
                                    <input
                                      type="text"
                                      placeholder="Expire Date*"
                                      id="date"
                                      name="date"
                                    />
                                  </div> */}
                                  <div className="col-lg-12 col-md-12 col-12">
                                    <div className="submit-btn-area text-center">
                                      <button
                                        className="theme-btn"
                                        type="submit"
                                        disabled
                                      >
                                        Place Order
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Checkout;

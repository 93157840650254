function ShippingAndDelivery() {
  return (
    <div className="compalinace-page">
      <div className="compalinace-content">
        <div className="content-container">
          <p className="content-head">Shipping &amp; Delivery Policy</p>
          <div className="content-seprater"></div>
          <p className="updated-date">Last updated on Nov 18th 2024</p>

          <p className="content-text">
            Shipping is not applicable for business.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ShippingAndDelivery;

import { Link } from 'react-router-dom';

function HeroSection() {
  return (
    <div className="wpo-hero-slider">
      <div className="container container-fluid-sm">
        <div className="hero-slider">
          <div className="hero-slider-item">
            <div className="slider-bg">
              <img src="/assets/images/marketing.png" alt="" />
            </div>
            <div className="slider-content">
              <div className="slide-title">
                <h2>Amplify Your Book's Reach</h2>
              </div>
              <Link to={`/blogs`} className="theme-btn">
                Read Now
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <ul className="hero-social">
        <li>
          <i className="ti-facebook"></i>
        </li>
        <li>
          <i className="ti-instagram"></i>
        </li>
        <li>
          <i className="ti-linkedin"></i>
        </li>
        <li>
          <i className="ti-twitter-alt"></i>
        </li>
      </ul> */}
    </div>
  );
}

export default HeroSection;

function RefundsAndCancellations() {
  return (
    <div className="compalinace-page">
      <div className="compalinace-content">
        <div className="content-container">
          <p className="content-head">Cancellation &amp; Refund Policy</p>
          <div className="content-seprater"></div>
          <p className="updated-date">Last updated on Nov 18th 2024</p>
          <p className="content-text">
            No cancellations &amp; Refunds are entertained
          </p>
        </div>
      </div>
    </div>
  );
}

export default RefundsAndCancellations;

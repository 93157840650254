import Middlebar from './Middlebar';
import Topbar from './Topbar';

function Navbar() {
  return (
    <header id="header" className="fixed-top">
      <Topbar />
      <Middlebar />
    </header>
  );
}

export default Navbar;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [], // Array of items in the cart
  totalQuantity: 0, // Total number of items
  totalPrice: 0, // Total cost of items
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart(state, action) {
      const { id, name, price, quantity = 1 } = action.payload;
      const existingItem = state.items.find((item) => item.id === id);

      if (!existingItem) {
        state.items.push({
          id,
          name,
          price,
          quantity,
          totalPrice: price * quantity,
        });
        state.totalQuantity += quantity;
        state.totalPrice += price * quantity;
      }
    },
    removeFromCart(state, action) {
      const { id } = action.payload;
      const existingItem = state.items.find((item) => item.id === id);

      if (existingItem) {
        state.totalQuantity -= existingItem.quantity;
        state.totalPrice -= existingItem.totalPrice;
        state.items = state.items.filter((item) => item.id !== id);
      }
    },
    clearCart(state) {
      state.items = [];
      state.totalQuantity = 0;
      state.totalPrice = 0;
    },
    updateCartPrices(state, action) {
      const { conversionRate, currency } = action.payload;

      state.items = state.items.map((item) => {
        const isUSD = currency === 'USD';
        const updatedPrice = isUSD
          ? (parseFloat(item.price) * conversionRate).toFixed(2)
          : Math.floor(parseFloat(item.price) / conversionRate).toFixed(2);

        return {
          ...item,
          price: parseFloat(updatedPrice),
          totalPrice: parseFloat(updatedPrice) * item.quantity,
        };
      });

      state.totalPrice = state.items.reduce(
        (sum, item) => sum + item.totalPrice,
        0
      );
    },
  },
});

export const { addToCart, removeFromCart, clearCart, updateCartPrices } =
  cartSlice.actions;

export default cartSlice.reducer;

import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeFromCart } from '../../store/slices/cartSlice';

function Cart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const totalQuantity = useSelector((state) => state.cart?.totalQuantity);
  const cartData = useSelector((state) => state.cart?.items);
  const cartTotalPrice = useSelector((state) => state.cart?.totalPrice);
  const { currencySymbol } = useSelector((state) => state.services);

  const removeFromCartClicked = (id) => {
    dispatch(removeFromCart({ id }));
  };
  const onProceedCheckoutClicked = () => {
    if (cartData.length) {
      navigate('/checkout');
    } else {
      navigate('/');
    }
  };
  return (
    <>
      <section className="wpo-page-title">
        <h2 className="d-none">Hide</h2>
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="wpo-breadcumb-wrap">
                <ol className="wpo-breadcumb-wrap">
                  <li>
                    <Link to={`/`}>Home</Link>
                  </li>
                  <li>Cart</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="cart-area-s2 section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="single-page-title">
                <h2>Your Cart</h2>
                <p>There are {totalQuantity} products in this list</p>
              </div>
            </div>
          </div>
          <div className="cart-wrapper">
            <div className="row">
              <div className="col-lg-8 col-12">
                <div className="cart-item">
                  <table className="table-responsive cart-wrap">
                    <thead>
                      <tr>
                        <th className="images images-b">Product</th>
                        <th className="ptice">Price</th>
                        <th className="stock">Quantity</th>
                        <th className="ptice total">Subtotal</th>
                        <th className="remove remove-b">Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartData.length ? (
                        cartData.map((item) => {
                          return (
                            <tr className="wishlist-item" key={item.id}>
                              <td className="product-item-wish-first-cart">
                                {item.name}
                              </td>
                              <td className="ptice">
                                {currencySymbol} {item.totalPrice}
                              </td>
                              <td className="td-quantity">
                                <div className="quantity cart-plus-minus">
                                  {item.quantity}
                                </div>
                              </td>
                              <td className="ptice">
                                {currencySymbol} {item.totalPrice}
                              </td>
                              <td className="action">
                                <ul>
                                  <li className="w-btn">
                                    <button
                                      className="w-remove-btn"
                                      aria-label="Remove from Cart"
                                      onClick={() =>
                                        removeFromCartClicked(item.id)
                                      }
                                    >
                                      <i className="fi ti-trash"></i>
                                    </button>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="wishlist-item no-item">
                          There is no product in this list.
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="cart-total-wrap">
                  <h3>Cart Totals</h3>
                  <div className="total">
                    <h4>Total</h4>
                    <span>
                      {currencySymbol} {cartTotalPrice}
                    </span>
                  </div>
                  <button
                    className="theme-btn-s2"
                    onClick={onProceedCheckoutClicked}
                  >
                    {cartData.length ? 'Proceed To CheckOut' : 'Add To Cart'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cart;

import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';

import { addToCart } from '../../store/slices/cartSlice';

function ServicesSection() {
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState(null);
  const dispatch = useDispatch();

  const { services, currencySymbol } = useSelector((state) => state.services);

  const handleClose = () => {
    setShow(false);
    setModalData(null);
  };
  const handleShow = (index) => {
    setModalData(services[index]);
    setShow(true);
  };

  const addToCartClicked = (index) => {
    const item = services[index];
    dispatch(
      addToCart({
        id: item.id,
        name: item.title,
        price: item.discountedAmount,
      })
    );
  };

  return (
    <>
      <section className="themart-interestproduct-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="wpo-section-title">
                <h2>Services Of Your Interest</h2>
              </div>
            </div>
          </div>
          <div className="product-wrap">
            <div className="row">
              {services.map((service, index) => {
                return (
                  <div
                    className="col-lg-3 col-md-4 col-sm-6 col-12"
                    key={service.id}
                  >
                    <div className="product-item">
                      <div className="image">
                        <img src={service.imgUrl} alt="" />
                        <ul className="cart-wrap">
                          <li>
                            <button
                              data-bs-toggle="tooltip"
                              data-bs-html="true"
                              title="Quick View"
                              onClick={() => handleShow(index)}
                            >
                              <i className="fi flaticon-eye"></i>
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="text">
                        <h2>{service.title}</h2>
                        <div className="price">
                          <span className="present-price">
                            {currencySymbol} {service.discountedAmount}
                          </span>
                          <del className="old-price">
                            {currencySymbol} {service.amount}
                          </del>
                        </div>
                        <div className="shop-btn">
                          <button
                            className="theme-btn-s2"
                            onClick={() => addToCartClicked(index)}
                          >
                            Add To Cart
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      {/* Modal */}
      {show && modalData && (
        <Modal
          show={true}
          onHide={handleClose}
          dialogClassName="quickview-dialog"
        >
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <i class="ti-close"></i>
          </button>
          <div class="modal-body d-flex">
            <div class="product-details">
              <div class="row">
                <div class="col-lg-5">
                  <div class="product-single-img">
                    <div class="modal-product">
                      <div class="item">
                        <img src={modalData.imgUrl} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-7">
                  <div class="product-single-content">
                    <h5>{modalData.title}</h5>
                    <h6>
                      {currencySymbol} {modalData.discountedAmount}
                    </h6>
                    <ul class="rating">
                      <li>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </li>
                      <li>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </li>
                      <li>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </li>
                      <li>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </li>
                      <li>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </li>
                    </ul>
                    <p>{modalData.summary}</p>
                    <div class="pro-single-btn">
                      <button class="theme-btn">Add To Cart</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default ServicesSection;

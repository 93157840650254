import { Link } from 'react-router-dom';
import { blogs } from '../../static-data/blogs';

function Blogs() {
  const BlogPosts = blogs;
  return (
    <>
      <section className="wpo-page-title">
        <h2 className="d-none">Hide</h2>
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="wpo-breadcumb-wrap">
                <ol className="wpo-breadcumb-wrap">
                  <li>
                    <Link to={`/`}>Home</Link>
                  </li>
                  <li>Blog</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wpo-blog-pg-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-lg-10 offset-lg-1">
              <div className="wpo-blog-content">
                {BlogPosts.map((post, index) => {
                  return (
                    <div className="post format-standard-image" key={post.id}>
                      <div className="entry-media">
                        <img src={post.imgUrl} alt="" />
                      </div>
                      <div className="entry-meta">
                        <ul>
                          <li>
                            <i className="fi flaticon-user"></i> By Twenty Nine
                            Services{' '}
                          </li>
                          <li>
                            <i className="fi flaticon-calendar-1"></i>{' '}
                            {post.postDate}
                          </li>
                        </ul>
                      </div>
                      <div className="entry-details">
                        <h3>{post.title}</h3>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: post.shortDescription,
                          }}
                        />
                        <Link to={`/blog/${post.id}`} className="read-more">
                          READ MORE...
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blogs;

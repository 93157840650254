import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { services } from '../../static-data/services';

const initialState = {
  services: services,
  currency: 'INR',
  currencySymbol: '₹',
  conversionRate: 0.012,
  loading: false,
  error: null,
};

export const fetchConversionRate = createAsyncThunk(
  'currency/fetchConversionRate',
  async (_, { getState }) => {
    const { currency } = getState().services;
    const apiUrl = `https://api.exchangerate-api.com/v4/latest/${currency}`;
    const response = await fetch(apiUrl).then((response) => response.json());
    return response.data.rates.USD; // Assuming we need USD rates.
  }
);

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setCurrency: (state, action) => {
      state.currency = action.payload;
      const isUSD = action.payload === 'USD';
      state.currencySymbol = isUSD ? '$' : '₹';
    },
    updatePrices: (state) => {
      const isUSD = state.currency === 'USD';
      state.services = state.services.map((service) => ({
        ...service,
        amount: isUSD
          ? (parseFloat(service.amount) * state.conversionRate).toFixed(2)
          : Math.floor(
              parseFloat(service.amount) / state.conversionRate
            ).toFixed(2),
        discountedAmount: isUSD
          ? (
              parseFloat(service.discountedAmount) * state.conversionRate
            ).toFixed(2)
          : Math.floor(
              parseFloat(service.discountedAmount) / state.conversionRate
            ).toFixed(2),
      }));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConversionRate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchConversionRate.fulfilled, (state, action) => {
        state.conversionRate = action.payload;
        state.loading = false;
      })
      .addCase(fetchConversionRate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setCurrency, updatePrices } = servicesSlice.actions;
export default servicesSlice.reducer;

function OfferSection() {
  return (
    <section className="themart-upcoming-offer-section section-padding mb-5">
      <div className="container">
        <div className="upcoming-offer">
          <div className="left-shape">
            <svg width="448" height="448" viewBox="0 0 448 448" fill="none">
              <path
                d="M448 224C448 347.712 347.712 448 224 448C100.288 448 0 347.712 0 224C0 100.288 100.288 0 224 0C347.712 0 448 100.288 448 224ZM13.8949 224C13.8949 340.038 107.962 434.105 224 434.105C340.038 434.105 434.105 340.038 434.105 224C434.105 107.962 340.038 13.8949 224 13.8949C107.962 13.8949 13.8949 107.962 13.8949 224Z"
                fill="#F1E2CC"
              />
              <path
                d="M405 224C405 323.964 323.964 405 224 405C124.036 405 43 323.964 43 224C43 124.036 124.036 43 224 43C323.964 43 405 124.036 405 224ZM56.2246 224C56.2246 316.66 131.34 391.775 224 391.775C316.66 391.775 391.775 316.66 391.775 224C391.775 131.34 316.66 56.2246 224 56.2246C131.34 56.2246 56.2246 131.34 56.2246 224Z"
                fill="#F1E2CC"
              />
              <path
                d="M360 224C360 299.111 299.111 360 224 360C148.889 360 88 299.111 88 224C88 148.889 148.889 88 224 88C299.111 88 360 148.889 360 224ZM100.433 224C100.433 292.244 155.756 347.567 224 347.567C292.244 347.567 347.567 292.244 347.567 224C347.567 155.756 292.244 100.433 224 100.433C155.756 100.433 100.433 155.756 100.433 224Z"
                fill="#F1E2CC"
              />
            </svg>
          </div>
          {/* <div className="left-image">
            <img src="assets/images/upcomming-left.png" alt="" />
          </div> */}
          <div className="right-shape">
            <svg width="448" height="448" viewBox="0 0 448 448" fill="none">
              <path
                d="M448 224C448 347.712 347.712 448 224 448C100.288 448 0 347.712 0 224C0 100.288 100.288 0 224 0C347.712 0 448 100.288 448 224ZM13.8949 224C13.8949 340.038 107.962 434.105 224 434.105C340.038 434.105 434.105 340.038 434.105 224C434.105 107.962 340.038 13.8949 224 13.8949C107.962 13.8949 13.8949 107.962 13.8949 224Z"
                fill="#F1E2CC"
              />
              <path
                d="M405 224C405 323.964 323.964 405 224 405C124.036 405 43 323.964 43 224C43 124.036 124.036 43 224 43C323.964 43 405 124.036 405 224ZM56.2246 224C56.2246 316.66 131.34 391.775 224 391.775C316.66 391.775 391.775 316.66 391.775 224C391.775 131.34 316.66 56.2246 224 56.2246C131.34 56.2246 56.2246 131.34 56.2246 224Z"
                fill="#F1E2CC"
              />
              <path
                d="M360 224C360 299.111 299.111 360 224 360C148.889 360 88 299.111 88 224C88 148.889 148.889 88 224 88C299.111 88 360 148.889 360 224ZM100.433 224C100.433 292.244 155.756 347.567 224 347.567C292.244 347.567 347.567 292.244 347.567 224C347.567 155.756 292.244 100.433 224 100.433C155.756 100.433 100.433 155.756 100.433 224Z"
                fill="#F1E2CC"
              />
            </svg>
          </div>
          {/* <div className="right-image">
            <img src="assets/images/upcomming-right.png" alt="" />
          </div> */}
          <div className="section-title-text">
            <h2>Christmas Sale</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="text">
                <div className="shape-text">
                  Up To{' '}
                  <div className="shape-single">
                    <div className="shape">
                      <svg
                        width="158"
                        height="159"
                        viewBox="0 0 158 159"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M156.059 58C146.681 24.5386 115.956 0 79.5 0C35.5934 0 0 35.5934 0 79.5C0 123.407 35.5934 159 79.5 159C117.749 159 149.689 131.988 157.285 96H147.228C139.817 126.526 112.306 149.193 79.5 149.193C41.0096 149.193 9.80698 117.99 9.80698 79.5C9.80698 41.0096 41.0096 9.80698 79.5 9.80698C110.488 9.80698 136.752 30.031 145.814 58H156.059Z"
                          fill="url(#paint0_linear_62_180)"
                        />

                        <defs>
                          <linearGradient
                            id="paint0_linear_62_180"
                            x1="78.6428"
                            y1="0"
                            x2="78.6428"
                            y2="159"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0" stopColor="#95CD2F" />
                            <stop offset="1" stopColor="#63911F" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    20
                  </div>
                  % Off
                </div>
                {/* <a className="upcoming-btn" href="product.html">
                  Shop Now
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OfferSection;

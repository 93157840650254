export const blogs = [
  {
    id: 1,
    title: `Little Pages and Big Adventures: A Tale of Endless Wonder`,
    postDate: '23 Nov 2024',
    shortDescription: `
    <p>
        Books have always been the doorway to a thousand worlds, and <strong>"Little Pages and Big Adventures"</strong> is no exception. 
        This enchanting children's book takes young readers on a magical journey, 
        proving that even the tiniest pages can hold the grandest adventures.
    </p>`,
    description: `
    <p>
        Books have always been the doorway to a thousand worlds, and <strong>"Little Pages and Big Adventures"</strong> is no exception. 
        This enchanting children's book takes young readers on a magical journey, 
        proving that even the tiniest pages can hold the grandest adventures.
    </p>

    <h4>The Story Behind the Story</h4>
    <p>
        At its heart, <strong>"Little Pages and Big Adventures"</strong> is about curiosity and imagination. 
        Meet Mia, a bright and curious child who stumbles upon a mysterious library where books aren't just books—they are alive! 
        As she opens each glowing tome, fantastical worlds unfold, from gallant knights in shimmering castles to talking animals in lush, enchanted forests.
    </p>
    <p>
        Every adventure starts with a single page, and Mia’s journey reminds readers of the boundless magic hiding in their bookshelves.
    </p>

    <h4>Why This Book?</h4>
    <p>
        <strong>"Little Pages and Big Adventures"</strong> is more than just a story. 
        It’s an invitation for children aged 5-10 to embrace the joy of reading. 
        The book inspires creativity, curiosity, and a love for storytelling.
    </p>`,
    imgUrl: '/assets/images/blog/little-pages.webp',
  },
];

import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { blogs } from '../../static-data/blogs';

function SingleBlog() {
  const { id } = useParams();
  const postData = blogs.find((blog) => blog.id === parseInt(id, 10));
  if (!postData) {
    return <div>Blog not found</div>;
  }
  return (
    <>
      <section className="wpo-page-title">
        <h2 className="d-none">Hide</h2>
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="wpo-breadcumb-wrap">
                <ol className="wpo-breadcumb-wrap">
                  <li>
                    <Link to={`/`}>Home</Link>
                  </li>
                  <li>{postData.title}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wpo-blog-pg-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-lg-10 offset-lg-1">
              <div className="wpo-blog-content">
                <div className="post format-standard-image">
                  <div className="entry-media">
                    <img src={postData?.imgUrl} alt="" />
                  </div>
                  <div className="entry-meta">
                    <ul>
                      <li>
                        <i className="fi flaticon-user"></i> By Twenty Nine
                        Services{' '}
                      </li>
                      <li>
                        <i className="fi flaticon-calendar-1"></i>{' '}
                        {postData?.postDate}
                      </li>
                    </ul>
                  </div>
                  <div className="entry-details">
                    <h3>{postData?.title}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: postData?.description,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SingleBlog;

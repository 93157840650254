function Auth() {
  return (
    <div className="wpo-login-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <form className="wpo-accountWrapper" action="#">
              <div className="wpo-accountInfo">
                <div className="wpo-accountInfoHeader">
                  <a href="index.html">
                    <img src="assets/images/logo-2.svg" alt="" />
                  </a>
                </div>
                <div className="image">
                  <img src="assets/images/login.svg" alt="" />
                </div>
              </div>
              <div className="wpo-accountForm form-style">
                <div className="fromTitle">
                  <h2>Login</h2>
                  <p>Sign into your pages account</p>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <label>Email</label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      placeholder="demo@gmail.com"
                    />
                  </div>
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        className="pwd6"
                        type="password"
                        placeholder=""
                        name="pass"
                      />
                      <span className="input-group-btn">
                        <button
                          className="btn btn-default reveal6"
                          type="button"
                        >
                          <i className="ti-eye"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="check-box-wrap">
                      <div className="input-box">
                        <input
                          type="checkbox"
                          id="fruit4"
                          name="fruit-4"
                          value="Strawberry"
                        />
                        <label htmlFor="fruit4">Remember Me</label>
                      </div>
                      <div className="forget-btn">
                        <a href="forgot.html">Forgot Password?</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-12">
                    <button type="submit" className="wpo-accountBtn">
                      Login
                    </button>
                  </div>
                </div>
                <h4 className="or">
                  <span>OR</span>
                </h4>
                <ul className="wpo-socialLoginBtn">
                  <li>
                    <button className="facebook" type="button">
                      <span>
                        <i className="ti-facebook"></i>
                      </span>
                    </button>
                  </li>
                  <li>
                    <button className="twitter" type="button">
                      <span>
                        <i className="ti-twitter"></i>
                      </span>
                    </button>
                  </li>
                  <li>
                    <button className="linkedin" type="button">
                      <span>
                        <i className="ti-linkedin"></i>
                      </span>
                    </button>
                  </li>
                </ul>
                <p className="subText">
                  Don't have an account?{' '}
                  <a href="register.html">Create free account</a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Auth;

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Home from './pages/home/Home';
import Blogs from './components/blogs/Blogs';
import SingleBlog from './components/single-blog/SingleBlog';
import PrivacyPolicy from './pages/privacy/Privacy';
import RefundsAndCancellations from './pages/refund-and-cancellation/RefundsAndCancellations';
import TermsAndConditions from './pages/terms-and-conditions/TermsAndConditions';
import MainLayout from './components/layouts/MainLayout';
import ShippingAndDelivery from './pages/shipping-and-delivery/ShippingAndDelivery';
import Auth from './pages/auth/Auth';
import Cart from './pages/cart/Cart';
import Checkout from './pages/checkout/Checkout';

function App() {
  return (
    <div className="page-wrapper">
      <BrowserRouter basename="/">
        <MainLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/cancellation-and-refund"
              element={<RefundsAndCancellations />}
            />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route
              path="/shipping-and-delivery"
              element={<ShippingAndDelivery />}
            />
            <Route path="/blog/:id" element={<SingleBlog />} />
            <Route path="*" element={<div>Page Not Found</div>} />
          </Routes>
        </MainLayout>
      </BrowserRouter>
    </div>
  );
}

export default App;

export const services = [
  {
    id: 1,
    title: 'Book Cover Design',
    imgUrl: 'assets/images/book-cover.webp',
    amount: '42999.00',
    discountedAmount: '32999.00',
    summary: `Transform your manuscript into a captivating visual experience with our professional book cover design service. Whether you're an author, publisher, or self-publisher, we craft stunning, genre-specific designs that capture your story’s essence and grab readers' attention. From striking typography to compelling imagery, each cover is tailored to reflect your book’s unique identity and market appeal. Let us help your book stand out on the shelf or online marketplace!`,
  },
  {
    id: 2,
    title: 'Book Editing Services',
    imgUrl: 'assets/images/book-editing.webp',
    amount: '50999.00',
    discountedAmount: '39999.00',
    summary: `Elevate your manuscript with professional editing tailored to your needs. From developmental editing to polish your story’s structure and flow, to meticulous copyediting and proofreading for grammar, style, and consistency, we ensure your book is ready for publication. Whether you're a first-time author or an experienced writer, our personalized approach enhances your voice while meeting industry standards. Let’s make your book the best it can be!`,
  },
  {
    id: 3,
    title: 'Website Design & Development',
    imgUrl: 'assets/images/A-modern-website-design.webp',
    amount: '67999.00',
    discountedAmount: '59999.00',
    summary: `We specialize in creating professional, customized websites for authors to showcase their work, connect with readers, and build their online presence. Our services include stunning design, intuitive navigation, responsive layouts, and seamless integration of features like book showcases, blogs, mailing lists, and e-commerce. Whether you're a debut author or an established writer, we help bring your unique voice to life online.`,
  },
  {
    id: 4,
    title: 'Self-Publishing Assistance',
    imgUrl: 'assets/images/self-publishing-assistance.webp',
    amount: '23999.00',
    discountedAmount: '18999.00',
    summary: `Helping authors bring their stories to life with personalized support for every step of the self-publishing journey. From manuscript editing and formatting to cover design, distribution, and marketing strategies, we provide the tools and expertise you need to publish professionally and confidently. Let us help you turn your book dream into reality!`,
  },
  {
    id: 5,
    title: 'Book Formatting',
    imgUrl: 'assets/images/book-formatting.webp',
    amount: '33999.00',
    discountedAmount: '26999.00',
    summary: `We provide professional book formatting services to ensure your manuscript is polished and ready for publication. Whether you’re publishing an eBook, paperback, or hardcover, We will format your book to meet the specific guidelines of platforms like Amazon KDP, IngramSpark, and others. From clean layouts and consistent styling to chapter headings and page numbering, We focus on creating a visually appealing, reader-friendly design that reflects the quality of your work. Perfect for self-publishing authors who want their book to shine!`,
  },
  {
    id: 6,
    title: 'Ghost Writing',
    imgUrl: 'assets/images/ghost-writing.webp',
    amount: '66999.00',
    discountedAmount: '56999.00',
    summary: `Transform your ideas into compelling written content with professional ghostwriting. Whether you need a book, article, blog post, or speech, we craft well-researched, engaging, and tailored pieces that capture your unique voice and vision. Let us handle the words while you take the credit!`,
  },
  {
    id: 7,
    title: 'Content Consultation',
    imgUrl: 'assets/images/content-consultation.jpeg',
    amount: '25999.00',
    discountedAmount: '12998.00',
    summary: `Empower your brand with expert guidance in content creation, strategy, and execution. Whether you're crafting compelling stories, optimizing content for digital platforms, or refining your messaging, We provide personalized coaching to elevate your voice and maximize impact. Together, we’ll transform ideas into engaging content that connects with your audience and drives results.`,
  },
  {
    id: 8,
    title: 'Author Branding & Strategy',
    imgUrl: 'assets/images/author-branding.jpeg',
    amount: '29999.00',
    discountedAmount: '20999.00',
    summary: `Author Branding & Strategy helps writers build a powerful and authentic personal brand that resonates with their target audience. We craft tailored strategies to enhance your visibility, refine your messaging, and establish your unique voice across platforms. From creating a compelling brand identity to guiding you through audience engagement and marketing techniques, we empower authors to connect with readers, elevate their presence, and achieve their goals. Let your story shine with a brand that truly represents you.`,
  },
];

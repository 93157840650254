import { useLocation } from 'react-router-dom';
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';

function MainLayout({ children }) {
  const location = useLocation();

  const hideNavFooterRoutes = [
    '/privacy-policy',
    '/cancellation-and-refund',
    '/terms-and-conditions',
    '/shipping-and-delivery',
  ];
  const shouldHideNavFooter = hideNavFooterRoutes.includes(location.pathname);

  return (
    <>
      {!shouldHideNavFooter && <Navbar />}
      {children}
      {!shouldHideNavFooter && <Footer />}
    </>
  );
}

export default MainLayout;

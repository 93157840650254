import { Link } from 'react-router-dom';

function About() {
  return (
    <>
      <section className="wpo-page-title">
        <h2 className="d-none">Hide</h2>
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="wpo-breadcumb-wrap">
                <ol className="wpo-breadcumb-wrap">
                  <li>
                    <Link to={`/`}>Home</Link>
                  </li>
                  <li>About Us</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wpo-about-section section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-wrap">
                <div className="wpo-about-img">
                  <img src="assets/images/about-us.jpeg" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="wpo-about-text">
                <h4>ABOUT US</h4>
                <h2>
                  Turning Pages into Profits
                  <b>
                    <span>&</span>Stories into Success
                  </b>
                </h2>
                <p>
                  At Twenty Nine Services, we specialize in book advertising and
                  value-added services, helping authors, publishers, and
                  literary businesses shine in a competitive market. Based in
                  India, our mission is to transform your creative vision into a
                  commercial success through innovative marketing strategies,
                  personalized campaigns, and expert support. We combine
                  creativity with technology to offer tailored solutions, from
                  targeted advertising to enhancing reader engagement. With a
                  focus on delivering measurable results, our team is dedicated
                  to amplifying your brand’s reach while maintaining the essence
                  of your story. Partner with us to make your books resonate
                  with readers worldwide. At Twenty Nine Services,{' '}
                  <b>your success is our story.</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wpo-service-section">
        <div className="container">
          <div className="service-wrap">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="service-item">
                  <div className="service-item-img">
                    <img src="assets/images/service/1.png" alt="" />
                  </div>
                  <div className="service-item-text">
                    <h2>Customer Support</h2>
                    <p>Here to Help, Always!</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="service-item">
                  <div className="service-item-img">
                    <img src="assets/images/service/2.png" alt="" />
                  </div>
                  <div className="service-item-text">
                    <h2>24 X 7 Service</h2>
                    <p>Online Service For New Customer.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="service-item">
                  <div className="service-item-img">
                    <img src="assets/images/service/3.png" alt="" />
                  </div>
                  <div className="service-item-text">
                    <h2>Festival Offer</h2>
                    <p>New Online Special Festival Offer.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="themart-gallery-section themart-gallery-section-s2 section-padding"
        id="gallery"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="wpo-section-title">
                <h2>Image Gallery</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3" style={{ marginBottom: '50px' }}>
              <div className="card" style={{ maxWidth: '18rem' }}>
                <img
                  src="/assets/images/gallery/The-Wiggly-Woods-Adventure-book cover.webp"
                  className="card-img-top"
                  alt="..."
                />
              </div>
            </div>
            <div className="col-3" style={{ marginBottom: '50px' }}>
              <div className="card" style={{ maxWidth: '18rem' }}>
                <img
                  src="/assets/images/gallery/echoes-of-the-forgotten-book-cover.webp"
                  className="card-img-top"
                  alt="..."
                />
              </div>
            </div>
            <div className="col-3" style={{ marginBottom: '50px' }}>
              <div className="card" style={{ maxWidth: '18rem' }}>
                <img
                  src="/assets/images/gallery/The-Compass-of-My-Heart.webp"
                  className="card-img-top"
                  alt="..."
                />
              </div>
            </div>
            <div className="col-3" style={{ marginBottom: '50px' }}>
              <div className="card" style={{ maxWidth: '18rem' }}>
                <img
                  src="/assets/images/gallery/little-pages-book-cover.webp"
                  className="card-img-top"
                  alt="..."
                />
              </div>
            </div>
            <div className="col-3" style={{ marginBottom: '50px' }}>
              <div className="card" style={{ maxWidth: '18rem' }}>
                <img
                  src="/assets/images/gallery/The-Hunters-Paradox.webp"
                  className="card-img-top"
                  alt="..."
                />
              </div>
            </div>
            <div className="col-3" style={{ marginBottom: '50px' }}>
              <div className="card" style={{ maxWidth: '18rem' }}>
                <img
                  src="/assets/images/gallery/Fiona-and-the-Flying-Elephants-book-cover.webp"
                  className="card-img-top"
                  alt="..."
                />
              </div>
            </div>
            <div className="col-3" style={{ marginBottom: '50px' }}>
              <div className="card" style={{ maxWidth: '18rem' }}>
                <img
                  src="/assets/images/gallery/The-Library-of-Invisible-Lives.webp"
                  className="card-img-top"
                  alt="..."
                />
              </div>
            </div>
            <div className="col-3" style={{ marginBottom: '50px' }}>
              <div className="card" style={{ maxWidth: '18rem' }}>
                <img
                  src="/assets/images/gallery/Alchemy-in-the-Oven.webp"
                  className="card-img-top"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;

function PrivacyPolicy() {
  return (
    <div className="compalinace-page">
      <div className="compalinace-content">
        <div className="content-container">
          <p className="content-head">Privacy Policy</p>
          <div className="content-seprater"></div>
          <p className="updated-date">Last updated on Nov 18th 2024</p>
          <p className="content-text">
            This privacy policy sets out how Twenty Nine Services uses and
            protects any information that you give Twenty Nine Services when you
            visit their website and/or agree to purchase from them.
          </p>
          <p className="content-text">
            Twenty Nine Services is committed to ensuring that your privacy is
            protected. Should we ask you to provide certain information by which
            you can be identified when using this website, and then you can be
            assured that it will only be used in accordance with this privacy
            statement.
          </p>
          <p className="content-text">
            Twenty Nine Services may change this policy from time to time by
            updating this page. You should check this page from time to time to
            ensure that you adhere to these changes.
          </p>
          <p className="content-text">
            <strong>We may collect the following information:</strong>
          </p>
          <ul className="unorder-list">
            <li className="list-item">
              <p className="content-text list-text">Name</p>
            </li>
            <li className="list-item">
              <p className="content-text list-text">
                Contact information including email address
              </p>
            </li>
            <li className="list-item">
              <p className="content-text list-text">
                Demographic information such as postcode, preferences and
                interests, if required
              </p>
            </li>
            <li className="list-item">
              <p className="content-text list-text">
                Other information relevant to customer surveys and/or offers
              </p>
            </li>
          </ul>
          <p className="content-text">
            <strong>What we do with the information we gather</strong>
          </p>
          <p className="content-text">
            We require this information to understand your needs and provide you
            with a better service, and in particular for the following reasons:
          </p>
          <ul className="unorder-list">
            <li className="list-item">
              <p className="content-text list-text">Internal record keeping.</p>
            </li>
            <li className="list-item">
              <p className="content-text list-text">
                We may use the information to improve our products and services.
              </p>
            </li>
            <li className="list-item">
              <p className="content-text list-text">
                We may periodically send promotional emails about new products,
                special offers or other information which we think you may find
                interesting using the email address which you have provided.
              </p>
            </li>
            <li className="list-item">
              <p className="content-text list-text">
                From time to time, we may also use your information to contact
                you for market research purposes. We may contact you by email,
                phone, fax or mail. We may use the information to customise the
                website according to your interests.
              </p>
            </li>
          </ul>
          <p className="content-text">
            We are committed to ensuring that your information is secure. In
            order to prevent unauthorised access or disclosure we have put in
            suitable measures.
          </p>
          <p className="content-text">
            <strong>How we use cookies</strong>
          </p>
          <p className="content-text">
            A cookie is a small file which asks permission to be placed on your
            computer's hard drive. Once you agree, the file is added and the
            cookie helps analyze web traffic or lets you know when you visit a
            particular site. Cookies allow web applications to respond to you as
            an individual. The web application can tailor its operations to your
            needs, likes and dislikes by gathering and remembering information
            about your preferences.
          </p>
          <p className="content-text">
            We use traffic log cookies to identify which pages are being used.
            This helps us analyze data about webpage traffic and improve our
            website in order to tailor it to customer needs. We only use this
            information for statistical analysis purposes and then the data is
            removed from the system.
          </p>
          <p className="content-text">
            Overall, cookies help us provide you with a better website, by
            enabling us to monitor which pages you find useful and which you do
            not. A cookie in no way gives us access to your computer or any
            information about you, other than the data you choose to share with
            us.
          </p>
          <p className="content-text">
            You can choose to accept or decline cookies. Most web browsers
            automatically accept cookies, but you can usually modify your
            browser setting to decline cookies if you prefer. This may prevent
            you from taking full advantage of the website.
          </p>
          <p className="content-text">
            <strong>Controlling your personal information</strong>
          </p>
          <p className="content-text">
            You may choose to restrict the collection or use of your personal
            information in the following ways:
          </p>
          <ul className="unorder-list">
            <li className="list-item">
              <p className="content-text list-text">
                whenever you are asked to fill in a form on the website, look
                for the box that you can click to indicate that you do not want
                the information to be used by anybody for direct marketing
                purposes
              </p>
            </li>
            <li className="list-item">
              <p className="content-text list-text">
                if you have previously agreed to us using your personal
                information for direct marketing purposes, you may change your
                mind at any time by writing to or emailing us at
              </p>
            </li>
          </ul>
          <p className="content-text">
            We will not sell, distribute or lease your personal information to
            third parties unless we have your permission or are required by law
            to do so. We may use your personal information to send you
            promotional information about third parties which we think you may
            find interesting if you tell us that you wish this to happen.
          </p>
          <p className="content-text">
            If you believe that any information we are holding on you is
            incorrect or incomplete, please write to Flat No.:-LG-1 P. NO.-2,
            LALARPURA, SHRI GIRIRAJ VIHAR Jaipur RAJASTHAN 302021 . or contact
            us at or as soon as possible. We will promptly correct any
            information found to be incorrect.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;

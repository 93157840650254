import FeatureSection from '../../components/feature-section/FeatureSection';
import HeroSection from '../../components/hero-section/HeroSection';
import OfferSection from '../../components/offer-section/OfferSection';
import ServicesSection from '../../components/services-section/ServicesSection';

function Home() {
  return (
    <>
      <HeroSection />
      <FeatureSection />
      <OfferSection />
      <ServicesSection />
    </>
  );
}

export default Home;

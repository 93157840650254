import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import MiniCartModal from '../mini-cart-modal/MiniCartModal';

function Middlebar() {
  const totalQuantity = useSelector((state) => state.cart?.totalQuantity);
  const miniCartData = useSelector((state) => state.cart?.items);
  const miniCartTotalPrice = useSelector((state) => state.cart?.totalPrice);
  const [showMiniCart, setShowMiniCart] = useState(false);
  const handleMiniCartClose = () => setShowMiniCart(false);
  const handleMiniCartShow = () => {
    if (totalQuantity) {
      setShowMiniCart(true);
    }
  };
  let miniCartCssName = 'mini-cart-content';
  if (showMiniCart) {
    miniCartCssName += ' mini-cart-content-toggle';
  }

  return (
    <>
      <div className="header-middle">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2">
              <div className="navbar-header">
                <div className="navbar-brand">
                  <img src="/assets/images/logo-horizontal.png" alt="logo" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12"></div>
            <div className="col-lg-4 col-12">
              <div className="middle-right">
                <ul>
                  <li>
                    <Link to={`/`}>Home</Link>
                  </li>
                  <li>
                    <Link to={`/about`}>About</Link>
                  </li>
                  <li>
                    <Link to={`/contact-us`}>Contact</Link>
                  </li>
                  <li>
                    <Link to={`/blogs`}>Blog</Link>
                  </li>
                  {/* <li>
                    <Link to={`/auth`}>
                      <i className="fi flaticon-user-profile"></i>
                      <span>Login</span>
                    </Link>
                  </li> */}
                  <li>
                    <div className="mini-cart">
                      <button
                        className="cart-toggle-btn"
                        onClick={handleMiniCartShow}
                      >
                        {' '}
                        <i className="fi flaticon-add-to-cart"></i>
                        {totalQuantity && miniCartData.length ? (
                          <span className="cart-count">{totalQuantity}</span>
                        ) : (
                          ''
                        )}
                      </button>
                      {showMiniCart && miniCartData.length && (
                        <MiniCartModal
                          miniCartCssName={miniCartCssName}
                          onClose={handleMiniCartClose}
                          modalData={miniCartData}
                          miniCartTotalPrice={miniCartTotalPrice}
                        />
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Middlebar;

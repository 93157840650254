import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeFromCart } from '../../store/slices/cartSlice';

function MiniCartModal({
  miniCartCssName,
  onClose,
  modalData,
  miniCartTotalPrice,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currencySymbol } = useSelector((state) => state.services);

  const removeFromCartClicked = (id) => {
    dispatch(removeFromCart({ id }));
  };

  const onViewCartClick = () => {
    onClose();
    navigate('/cart');
  };

  const onCheckoutClicked = () => {
    onClose();
    navigate('/checkout');
  };

  return (
    <div className={miniCartCssName}>
      <button className="mini-cart-close" onClick={onClose}>
        <i className="ti-close"></i>
      </button>
      <div className="mini-cart-items">
        {modalData.map((item) => {
          return (
            <div className="mini-cart-item clearfix" key={item.id}>
              <div className="mini-cart-item-des">
                <span>{item.name}</span>
                <span className="mini-cart-item-price">
                  {currencySymbol} {item.totalPrice} x {item.quantity}
                </span>
                <button
                  className="mini-cart-item-quantity"
                  onClick={() => removeFromCartClicked(item.id)}
                >
                  <i className="ti-close"></i>
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <div className="mini-cart-action clearfix">
        <span className="mini-checkout-price">
          Subtotal:
          <span>
            {currencySymbol} {miniCartTotalPrice}
          </span>
        </span>
        <div className="mini-btn">
          <button className="view-cart-btn s1" onClick={onCheckoutClicked}>
            Checkout
          </button>
          <button className="view-cart-btn" onClick={onViewCartClick}>
            View Cart
          </button>
        </div>
      </div>
    </div>
  );
}

export default MiniCartModal;
